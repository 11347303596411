/**
 * useInterval
 * https://github.com/streamich/react-use/blob/master/src/useInterval.ts
 */
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

const useIntervalForGsap = (callback: Function, delay?: number | null) => {
  const savedCallback = useRef<Function>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (delay !== null) {
        const interval = setInterval(() => savedCallback.current(), delay || 0);
        return () => {
          clearInterval(interval);
        };
      }
    });

    return () => ctx.revert(); // cleanup;
  }, [delay]);
};

export default useIntervalForGsap;
