import { Link, useLoaderData } from '@remix-run/react';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { collection, getFirestore, limit, onSnapshot, orderBy, query, where, type QuerySnapshot } from 'firebase/firestore';
import gsap from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackgroundGradient } from '#app/components/BackgroundGradient.tsx';
import { NameAndCountry } from '#app/components/NameAndCountry.tsx';
import { Icon } from '#app/components/ui/Icon.tsx';
import useIntervalForGsap from '#app/hooks/useIntervalForGsap.ts';
import useTimeoutFn from '#app/hooks/useTimeout.ts';
import useToggle from '#app/hooks/useToggle.ts';
import { getCountryFullName } from '#app/lib/countries.ts';
import { type User, USER_PAGE_SIZE, USER_PICTURE_SIZE, type UsersPage } from '#app/lib/firebase/models/user.ts';
import { isSpaceInternet } from '#app/lib/helpers.ts';
import { type SpaceLoaderData } from '#app/routes/_app+/space.loader.server.ts';
import { Woman } from '../Woman.tsx';

let didInit = false;

/**
 * WOW Gallery
 * This component will display the first 2 pages of users and animate the gallery
 * When the first page of users is displayed, it will fetch the next page
 *
 * @constructor
 */
export const Gallery = () => {
  let { t } = useTranslation();
  // TODO: REMIX Issue here, see https://github.com/remix-run/remix/issues/7246
  // @ts-ignore
  const { user, space, users, totalUsersCount, gradients, firstPageFetched }: SpaceLoaderData = useLoaderData<SpaceLoaderData>();
  // console.log({ user });
  // console.log({ space });
  // console.log({ users });
  // console.log({ totalUsersCount });
  const womandContainerRef = useRef<HTMLDivElement>(null);
  const womanRef = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>();
  const [isRunning, setIsRunning] = useToggle(false);
  const [isInternetSpace] = useState(isSpaceInternet(space.name));

  // -----------------------------------
  // GALLERY QUEUE STATE
  // -----------------------------------
  const [totalCount, setTotalCount] = useState(totalUsersCount);
  const [usersQueue, setUsersQueue] = useState<Array<User>>(users as unknown as Array<User>);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentRealTimeUser, setCurrentRealTimeUser] = useState<User | undefined>(undefined);
  const [showLastRealTimeUser, setShowLastRealTimeUser] = useState(false);
  // const [fallbackUser, setFallbackUser] = useState<User | undefined>(undefined);
  // this index is used to cycle between the pictures DOM nodes
  const currentUserIndex = useRef(-1);
  // this is the number of the last fetched page
  const lastFetchedPage = useRef(-1);

  // -----------------------------------
  // TIMING SETTINGS
  // -----------------------------------
  const TIMER_DURATION = 5000; // timing between each picture
  // const transitionDuration = TIMER_DURATION / 1000; // timing of the picture transition
  const transitionDuration = (TIMER_DURATION - 500) / 1000; // timing of the picture transition

  /**
   * Create the imgs in the DOM for each user
   */
  const addPictures = useCallback((users: Array<User>) => {
    // console.log('DEBUG adding pictures to the DOM');
    users.forEach(user => {
      const img = document.createElement('img');
      // @ts-ignore
      img.src = user.pictureSrc;
      // img.loading = 'lazy';
      img.alt = `${user.name} · ${getCountryFullName(user.country)}`;
      img.dataset.userid = user.id; // this will be used to remove the img
      img.className = 'woman-picture';
      img.width = USER_PICTURE_SIZE;
      img.height = USER_PICTURE_SIZE;

      // @ts-ignore
      womanRef.current.insertBefore(img, womanRef.current.querySelector('svg'));
      // console.log(`DEBUG: added img for user ${user.id}`);
    });
  }, []);

  const deletePictures = useCallback((users: Array<User>) => {
    // remove old user pictures from the DOM
    users.forEach(user => {
      // @ts-ignore
      const img = womanRef.current.querySelector(`img[data-userid="${user.id}"]`);
      if (img) {
        // womanRef.current.removeChild(img);
        img.remove();
        // console.log(`DEBUG: removed img for user ${user.id}`);
      } else {
        console.warn(`img for user ${user.id} not found!!!`);
      }
    });
  }, []);

  // ##############################################################################################################
  // BROWSER FOCUS MANAGEMENT
  // ##############################################################################################################
  useEffect(() => {
    const disableLagSmoothing = () => {
      gsap.ticker.lagSmoothing(0);
      setIsRunning(false);
      console.log('🔔 GSAP lagSmoothing disabled!');
    };
    const enableLagSmoothing = () => {
      gsap.ticker.lagSmoothing(500, 33);
      setIsRunning(true);
      console.log('🔔 GSAP lagSmoothing enabled!');
    };

    window.addEventListener('blur', disableLagSmoothing, false);
    window.addEventListener('focus', enableLagSmoothing, false);

    return () => {
      window.removeEventListener('blur', disableLagSmoothing, false);
      window.removeEventListener('focus', enableLagSmoothing, false);
    };
  });

  // ##############################################################################################################
  // FIRST RUN (onmount)
  // ##############################################################################################################

  /**
   * doFirst: called only on component mount, it will set the currentUserIndex to 0, lastFetchedPage to 1,
   * and set the current user from the usersQueue
   */
  const doFirst = useCallback(() => {
    // console.log('🪵 DEBUG: doFirst!');
    let newIndex = 0;
    currentUserIndex.current = newIndex;
    lastFetchedPage.current = firstPageFetched + 1; // the page loader will load the first 2 pages!!
    setCurrentUser(usersQueue[newIndex] as unknown as User);
    // setFallbackUser(usersQueue[0]);

    // Create the list of user pictures for the current page, set the first picture visible
    if (womandContainerRef.current && womanRef.current) {
      // create the DOM elements for the images
      if (usersQueue && usersQueue.length > 0) {
        addPictures(usersQueue);
      }

      const firstWoman = womandContainerRef.current.querySelector<HTMLImageElement>('.woman-picture');
      if (firstWoman) {
        // console.log('DEBUG: doFirst, 1st woman visible!');
        firstWoman.style.opacity = '1';
      }
    }
  }, [firstPageFetched, usersQueue, addPictures]);

  useEffect(() => {
    if (!didInit) {
      didInit = true;

      // set the first user
      doFirst();

      // let's start with the loop
      setIsRunning(true);
    }
  }, [doFirst, setIsRunning]);

  // ##############################################################################################################
  // THE LOOP
  // ##############################################################################################################

  /**
   * Fetch new page of users, set totalCount, remove old users from DOM & usersQueue,
   * add new users to DOM & usersQueue
   */
  const fetchNextPage = useCallback(
    async (spaceId: string, pageIndexToFetch: number) => {
      // check if we are already on the last page
      let pageToFetch = pageIndexToFetch;
      const totalPages = Math.floor(totalCount / USER_PAGE_SIZE);
      // console.log(`🪵 DEBUG fetchNextPage.totalPages: ${totalPages}`);

      if (pageToFetch >= totalPages) {
        pageToFetch = 0;
      }

      // only for internet space, choose a RANDOM page to fetch
      // if (isInternetSpace) {
      //   pageToFetch = Math.min(Math.floor(Math.random() * totalPages), totalPages - 1);
      // }

      // console.log(`🪵 DEBUG fetchNextPage.pageToFetch: ${pageToFetch}`);

      try {
        const response = await fetch(`/api/gallery/${spaceId}/${pageToFetch}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const page = (await response.json()) as UsersPage;

        // update the total count of users and the current(last fetched) page
        setTotalCount(page.totalCount);
        lastFetchedPage.current = page.page;

        // users queue: must remove old page and add the new one
        const oldPageOfUsers = usersQueue.filter((x, index) => index < USER_PAGE_SIZE);
        const currentPageOfUsers = usersQueue.filter((x, index) => index >= USER_PAGE_SIZE);
        const newPageOfUsers = page.users as unknown as User[];
        setUsersQueue([...currentPageOfUsers, ...newPageOfUsers]);

        // remove old user pictures from the DOM
        deletePictures(oldPageOfUsers);
        // console.log(`🪵 DEBUG fetchNext deleted pics`);

        // add new
        addPictures(newPageOfUsers);
        // console.log(`🪵 DEBUG fetchNext added pics`);

        const newCurrentUserIndex = 0;
        currentUserIndex.current = newCurrentUserIndex;
        setCurrentUser(currentPageOfUsers[newCurrentUserIndex]);
      } catch (e) {
        console.error(e);
      }
    },
    [usersQueue, totalCount, addPictures, deletePictures]
  );

  const changePicture = useCallback(
    async (indexFrom: number, indexTo: number) => {
      // console.log(`🪵 DEBUG changePicture from ${indexFrom} to ${indexTo}`);
      // gsap.set(`.woman-picture:not(:nth-child(${indexTo}))`, { opacity: 0 });
      // gsap.set(`.woman-picture:nth-child(${indexTo})`, { opacity: 1 });

      tl.current = gsap.timeline({
        paused: true
        // onStart: () => {
        //   onComplete();
        // }
      });

      // transition current image
      tl.current.to(
        `.woman-picture:nth-child(${indexFrom})`,
        {
          opacity: 0,
          scale: 1,
          duration: transitionDuration
        },
        0
      );

      // transition next image
      tl.current.to(
        `.woman-picture:nth-child(${indexTo})`,
        {
          opacity: 1,
          scale: 1,
          duration: transitionDuration
        },
        `-=${transitionDuration}`
      );

      // drop animation
      tl.current.fromTo(
        '.drop svg',
        {
          scale: 0,
          opacity: 0.5
        },
        {
          scale: 3.5,
          opacity: 0,
          duration: transitionDuration,
          ease: 'power1.inOut'
        },
        '<'
      );

      tl.current.play();
    },
    [transitionDuration]
  );

  const doLoop = useCallback(async () => {
    // console.log(`🪵 DEBUG doLoop start currentUserIndex: ${currentUserIndex.current}`);

    let doFetch = false;
    // calculate the new user index
    let transitionIndexFrom = currentUserIndex.current;
    let transitionIndexTo = transitionIndexFrom + 1;
    // rotate within the page + 1 (at the 5th restart the loop)
    if (transitionIndexTo > USER_PAGE_SIZE) {
      transitionIndexTo = 0;
    }

    // console.log(`🪵 DEBUG transitioning from ${transitionIndexFrom} to ${transitionIndexTo}`);
    changePicture(transitionIndexFrom + 1, transitionIndexTo + 1);

    let newCurrentUserIndex = currentUserIndex.current + 1;

    // if we are in the 2nd page, then fetch the next one and do the stuff
    if (newCurrentUserIndex === USER_PAGE_SIZE) {
      doFetch = true;
      newCurrentUserIndex = 0;
    }

    if (doFetch) {
      // console.log(`🪵 DEBUG lastFetchedPage: ${lastFetchedPage.current}`);
      // console.log(`🪵 DEBUG fetching next page /api/gallery/${space.id}/${lastFetchedPage.current + 1}`);
      await fetchNextPage(String(space.id), lastFetchedPage.current + 1);
      doFetch = false;
    } else {
      currentUserIndex.current = newCurrentUserIndex;
      setCurrentUser(usersQueue[newCurrentUserIndex] as unknown as User);
      // console.log(`🪵 DEBUG doLoop set new currentUserIndex: ${newCurrentUserIndex}`);
      // console.log(`🪵 DEBUG                ${usersQueue[newCurrentUserIndex].name}`);
    }
  }, [fetchNextPage, changePicture, space.id, usersQueue]);

  useIntervalForGsap(
    () => {
      doLoop().catch(error => {
        console.error(error);
      });
    },
    isRunning ? TIMER_DURATION : null
  );

  // ##############################################################################################################
  // REAL TIME USERS!!!
  // ##############################################################################################################
  useEffect(() => {
    // console.log({ isInternetSpace });
    if (isInternetSpace) {
      return;
    }

    console.log(`🔔🔔🔔 PREPARING FOR REAL TIME`);
    const firebaseConfig = {
      apiKey: window.ENV.FIREBASE_WEB_API_KEY,
      authDomain: window.ENV.FIREBASE_AUTHDOMAIN,
      projectId: window.ENV.FIREBASE_PROJECT_ID,
      storageBucket: window.ENV.FIREBASE_STORAGEBUCKET,
      messagingSenderId: window.ENV.FIREBASE_MESSAGING_SENDER_ID,
      appId: window.ENV.FIREBASE_APPID
    };
    const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
    const db = getFirestore(app);

    const realTimeUserIsArrived = (user: User) => {
      // stop the loop
      setIsRunning(false);

      setCurrentRealTimeUser(user);
      setShowLastRealTimeUser(true);

      const restoreLoop = () => {
        setShowLastRealTimeUser(false);
        setCurrentRealTimeUser(undefined);
        setIsRunning(true);
      };
      // TODO: set timeout to restart the loop
      setTimeout(restoreLoop, 10 * 1000);
    };

    const handleRealTimeUserUpdates = (querySnapshot: QuerySnapshot) => {
      querySnapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          // const source = doc.metadata.hasPendingWrites ? 'has pending writes!' : 'ok!';
          const user = change.doc.data() as User;

          // if (!doc.metadata.hasPendingWrites && user) {
          console.log(`🔔🔔🔔 REAL TIME user - ${user.id}`);
          console.log({ user });
          realTimeUserIsArrived(user);
          // }
        }
      });
    };

    const q = query(
      collection(db, 'users'),
      where('legalAccepted', '==', true),
      where('spaceName', 'array-contains', space.name.toLowerCase()),
      where('pictureTermsAccepted', '==', true),
      where('pictureIsValid', '==', true),
      where('name', '!=', ''),
      orderBy('name', 'asc'),
      orderBy('createdAt', 'desc'),
      orderBy('updatedAt', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(q, handleRealTimeUserUpdates, err => {
      console.error(`🙈 Error onSnapshot for space ${space.name}:`);
      console.error(err);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ##############################################################################################################
  // AUDIO PLAYER
  // ##############################################################################################################
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioIsPlaying, setAudioIsPlaying] = useToggle(false);

  /**
   * Load the muisic file only for Internet space
   */
  const getMusic = useCallback(async () => {
    // if (!isInternetSpace) {
    //   return false;
    // }

    try {
      const response = await fetch('/media/GAVARD MUSIC light.mp3');
      const blob = new Blob([await response.arrayBuffer()], { type: 'audio/mp3' });

      if (audioRef.current) {
        audioRef.current.src = window.URL.createObjectURL(blob);
        audioRef.current.loop = true;
        audioRef.current.volume = 0.2;
        audioRef.current.pause();
      }

      console.log('🪵 Web Audio loaded!');
    } catch (error) {
      console.error(error);
    }
  }, []);

  useTimeoutFn(getMusic, 700);

  /**
   * Manage the play/pause of the music
   */
  useEffect(() => {
    if (audioRef.current) {
      if (audioIsPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [audioIsPlaying]);

  // ##############################################################################################################

  /**
   * Toggle the fullscreen mode, only for non internet spaces
   */
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // If the document is not in full screen mode make the video full screen
      document.body.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return (
    <>
      <section className={`wow-gallery ${space.isRotated ? 'is-rotated' : ''}`}>
        <BackgroundGradient duration={TIMER_DURATION / 1000} gradients={gradients} />

        <h1>{t('wow_title')}</h1>

        {showLastRealTimeUser && currentRealTimeUser && <Woman user={currentRealTimeUser} className="centered" />}

        {/*{!showLastRealTimeUser && (*/}
        <div ref={womandContainerRef} className="woman-container" hidden={showLastRealTimeUser}>
          <div ref={womanRef} className="woman">
            {usersQueue && usersQueue.length > 0 && currentUser && (
              <svg viewBox="0 0 380 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>{`${currentUser?.name} · ${getCountryFullName(currentUser?.country)}`}</title>
                <defs>
                  <path
                    id="woman-arc-down"
                    d="M37 190.5a152.496 152.496 0 0 0 94.141 140.892 152.512 152.512 0 0 0 116.718 0 152.506 152.506 0 0 0 82.533-82.533A152.512 152.512 0 0 0 342 190.5H37Z"
                    fill="#000"
                  />
                  <path
                    id="woman-arc-up"
                    d="M81.666 298.334a152.504 152.504 0 0 1-33.058-166.193A152.496 152.496 0 0 1 189.5 38a152.497 152.497 0 0 1 140.892 94.141 152.503 152.503 0 0 1-33.058 166.193L189.5 190.5 81.666 298.334Z"
                    fill="#000"
                  />

                  <pattern id={`pattern`} patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref={`#image-${currentUser?.counter}`} transform="translate(0 -.25) scale(.00246)" />
                  </pattern>
                  {/*<image id={`image-${fallbackUser?.counter}`} width="407" height="610" href={fallbackUser?.pictureSrc} />*/}
                </defs>
                {/*<g>*/}
                {/*  /!*Circle filled with the image*!/*/}
                {/*  <circle cx="188.5" cy="190.5" r="152.5" fill={`url(#pattern)`} />*/}
                {/*</g>*/}

                {/*Filter for water distortion*/}
                <filter id="distortionFilter">
                  <feTurbulence type="fractalNoise" baseFrequency="0.01 0.003" numOctaves="5" seed="2" stitchTiles="noStitch" x="0%" y="0%" width="100%" height="100%" result="noise" />
                  <feDisplacementMap
                    id="feDisplacementMap"
                    in="SourceGraphic"
                    in2="noise"
                    scale="0"
                    xChannelSelector="R"
                    yChannelSelector="B"
                    x="0%"
                    y="0%"
                    width="100%"
                    height="100%"
                    filterUnits="userSpaceOnUse"
                  />
                </filter>
                <text dy="-16" textAnchor="middle" width="380" fontFamily="Metropolis" fontWeight="bold" fill="#fff" fontSize="32">
                  <textPath startOffset="35%" xlinkHref="#woman-arc-up">
                    <NameAndCountry name={String(currentUser?.name)} country={getCountryFullName(currentUser?.country)} />
                  </textPath>
                </text>

                <text className="counter" dy="35" textAnchor="middle" width="380" fontFamily="Metropolis" fontWeight="400" fill="#fff" fontSize="32">
                  <textPath startOffset="30%" xlinkHref="#woman-arc-down">
                    {String(currentUser?.counter).padStart(6, '0')}
                  </textPath>
                </text>
              </svg>
            )}
          </div>
        </div>
        {/*)}*/}

        <div className="drop">
          <svg viewBox="0 0 305 305" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="152.5" cy="152.5" r="152.5" fill="url(#a)" style={{ mixBlendMode: 'screen' }} />
            <defs>
              <radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 0 152.5) scale(152.5)">
                <stop stopColor="#D9D9D9" stopOpacity="0" />
                <stop offset=".354" stopColor="#D9D9D9" stopOpacity=".81" />
                <stop offset=".698" stopColor="#D9D9D9" stopOpacity="0" />
                <stop offset="1" stopColor="#D9D9D9" stopOpacity=".375" />
              </radialGradient>
            </defs>
          </svg>
        </div>

        <footer className="wow-footer">
          <div className="wow-footer-left">
            {isInternetSpace && (
              <p className="total-users-count">
                <span className="visuallyhidden">{`${t('wow_total-count')}:`}</span>
                {totalCount && String(totalCount).padStart(6, '0')}
              </p>
            )}
          </div>

          <div className="wow-footer-center">
            {!user && isInternetSpace && (
              <Link to={'/join'} data-testid="join-button" className="button button-secondary">
                {t('wow_join')}
              </Link>
            )}
            {user && isInternetSpace && (
              <Link to={'/profile'} data-testid="profile-button" className="button button-secondary">
                {t('wow_profile')}
              </Link>
            )}
          </div>

          <div className="wow-footer-right">
            {!isInternetSpace && (
              <button type="button" className="toggle-full-screen" onClick={() => toggleFullScreen()}>
                <span className="visuallyhidden">Toggle fullscreen</span>
              </button>
            )}

            {isInternetSpace && (
              <>
                {/* eslint-disable-next-line remix-react-routes/use-link-for-routes */}
                <a href="/#about" className="about">
                  about
                </a>

                <button type="button" role="switch" aria-checked={!isRunning} className="button-gallery-toggle" onClick={() => setIsRunning(!isRunning)}>
                  <span className="visuallyhidden">{isRunning ? t('wow_pause-gallery') : t('wow_play-gallery')}</span>
                  {/*play*/}
                  <svg className="button-gallery-play" aria-hidden={true} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="11.25" />
                      <path d="M10 14.82V9.092a1 1 0 0 1 1.628-.778l3.438 2.772a1 1 0 0 1 .024 1.537l-3.438 2.957A1 1 0 0 1 10 14.82Z" />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="currentColor" d="M0 0h24v24H0z" />
                      </clipPath>
                    </defs>
                  </svg>

                  {/*pause*/}
                  <svg className="button-gallery-pause" aria-hidden={true} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="11.25" />
                      <path d="M10 15V9" />
                      <path d="M14 15V9" />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="currentColor" d="M0 0h24v24H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                <Link to="/search" className="wow-search-button">
                  <span className="visuallyhidden">{t('wow_search')}</span>
                  <Icon name="search" />
                </Link>
              </>
            )}

            {space && (space.showAudioToggle || isInternetSpace) && (
              <button
                type="button"
                role="switch"
                aria-checked={audioIsPlaying}
                className={`button-music-toggle ${audioIsPlaying ? 'music-on' : ''}`}
                onClick={() => setAudioIsPlaying(!audioIsPlaying)}
              >
                <div />
                <div />
                <div />
                <span className="visuallyhidden">Toggle music</span>
              </button>
            )}
            <audio ref={audioRef} className="visuallyhidden" controls={true} autoPlay={true} loop={true}></audio>
          </div>
        </footer>
      </section>
    </>
  );
};
